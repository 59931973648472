import * as _ from 'lodash';
import {createFeatureSelector, createSelector} from '@ngrx/store';

import {PaymasterFeatureState} from '../reducers';
import {PaymasterUiState} from '../reducers/paymaster-ui.reducer';
import {BatchStatus} from '../../../../common/batch.model';
import {FilterParams, LiteBrowseQueryParams} from '../../models/query-params.model';
import {TextFilter, TextFilterConfig} from '../../shared/components/text-filter/text-filter.model';
import {PaymasterBatchConstants} from '../../../../constants/paymaster-batch.constants';
import {FilterOption} from '../../shared/components/filter-panel/filter-item.model';
import {DropdownFilter} from '../../shared/components/dropdown-filter/dropdown-filter.model';
import {selectInvoiceLookup, selectPaymasterLookup, selectPaymasterManagerLookup, selectPaymasters, selectProjectLookup} from './paymaster-lookup.selector';
import {ProjectSummary} from '../../../../common/project.summary.model';

const selectPaymasterFeatureState = createFeatureSelector<PaymasterFeatureState>('paymaster');

const selectPaymasterUiRootState = createSelector(
  selectPaymasterFeatureState,
  (state: PaymasterFeatureState): PaymasterUiState => state && state.ui
);

export const selectLiteBatchQueryParams = createSelector(
  selectPaymasterUiRootState,
  (state: PaymasterUiState) => state && state.liteBatchQueryParams
);

export const selectFilters = createSelector(
  selectLiteBatchQueryParams,
  (state: LiteBrowseQueryParams) => state && state.filters
);

export const selectSelectedPaymasters = createSelector(
  selectFilters,
  (filters: FilterParams[]) => filters && _.find(filters, {'filterType': 'paymasters'})?.value || []
);

export const selectSelectedBatchStatus = createSelector(
  selectFilters,
  (filters: FilterParams[]) => filters && _.find(filters, {'filterType': 'batchStatus'})?.value || []
);

export const selectSelectedSort = createSelector(
  selectLiteBatchQueryParams,
  (state: LiteBrowseQueryParams) => state && state.sort
);

export const selectIsAllLiteBatchesLoaded = createSelector(
  selectPaymasterUiRootState,
  (state: PaymasterUiState) => state && state.isLastLiteBatchLoaded
);

export const selectBatchesBrowseOffset = createSelector(
  selectLiteBatchQueryParams,
  (state: LiteBrowseQueryParams) => state && state.offset
);

export const selectIsIncludeProcessed = createSelector(
  selectSelectedBatchStatus,
  (batchStatus: string[]): boolean => _.includes(batchStatus, _.capitalize(BatchStatus.PROCESSED))
);

export const selectSelectedPaymasterManager = createSelector(
  selectPaymasterUiRootState,
  (state: PaymasterUiState) => state && state.selectedPaymasterManager
);

export const selectLoading = createSelector(
  selectPaymasterUiRootState,
  (state: PaymasterUiState ) => state ? state.loading : false
);

export const selectSearchType = createSelector(
  selectLiteBatchQueryParams,
  (state: LiteBrowseQueryParams) => state && state.searchType
);

export const selectSearchText = createSelector(
  selectLiteBatchQueryParams,
  (state: LiteBrowseQueryParams) => state && state.searchText
);

export const selectIsBTMSearchChanged = createSelector(
  selectPaymasterUiRootState,
  (state: PaymasterUiState) => state.isBTMSearchChanged ?? false
);

export const selectSelectedPaymasterFilters = createSelector(
  selectPaymasters,
  selectSelectedPaymasters,
  (paymasters, selectedPaymasters = []) => ({
    paymasters: _.map(selectedPaymasters, (email, idx) => {
      const {firstName = '', lastName = ''} = _.find(paymasters, {email}) ?? {};
      return {
        id: _.toString(idx),
        value: `${lastName}, ${firstName}`,
        type: 'paymasters'
      };
    })
  })
);

export const selectPaymasterBatchFilters = createSelector(
  selectPaymasterLookup,
  selectSelectedPaymasterFilters,
  (lookUpData, filters): DropdownFilter[] => {
    return [{
      titleOptions: PaymasterBatchConstants.PaymasterBatch.PaymasterBatchFilterTypes,
      options: lookUpData.paymasters,
      selectedItems: _.filter(filters.paymasters, ({value}) => value !== ', ')
    }];
  }
);

export const selectPaymasterManagerBatchFilters = createSelector(
  selectPaymasterManagerLookup,
  selectSelectedPaymasterManager,
  (lookUpData, selectedPaymasterManager): DropdownFilter[] => {
    return [{
      titleOptions: PaymasterBatchConstants.PaymasterBatch.PaymasterBatchFilterTypes,
      options: lookUpData.paymasters,
      selectedItems: selectedPaymasterManager ? [selectedPaymasterManager] : []
    }];
  }
);

export const selectSelectedBatchStatusFilters = createSelector(
  selectFilters,
  (filters: FilterParams[]): FilterOption[] => {
    const statusFilter = _.filter(filters, {filterType: 'batchStatus'});
    return _.map(_.head(statusFilter)?.value, (status) => ({
      id: _.find(PaymasterBatchConstants.PaymasterBatch.BatchStatusFilterOption, ['value', status])?.id,
      value: status,
      type: 'batchStatus'
    }));
  }
);

export const selectSelectedBatchPayrollExceptionsFilters = createSelector(
  selectFilters,
  (filters: FilterParams[]): FilterOption[] => {
    const payrollFilters = _.filter(filters, {filterType: 'hasPayrollExceptions'});
    return _.map(_.head(payrollFilters)?.value, (status) => ({
      id: _.find(PaymasterBatchConstants.PaymasterBatch.BatchPayrollExceptionsFilterOption, ['value', status])?.id,
      value: status,
      type: 'hasPayrollExceptions'
    }));
  }
);

export const selectSelectedBatchErrorFilters = createSelector(
  selectFilters,
  (filters: FilterParams[]): FilterOption[] => {
    const errorFilters = _.filter(filters, {filterType: 'hasAnyErrors'});
    return _.map(_.head(errorFilters)?.value, (status) => ({
      id: _.find(PaymasterBatchConstants.PaymasterBatch.BatchErrorFilterOption, ['value', status])?.id,
      value: status,
      type: 'hasAnyErrors'
    }));
  }
);

export const selectSelectedProjectFilters = createSelector(
  selectFilters,
  selectProjectLookup,
  (filters: FilterParams[], projects: ProjectSummary[]): FilterOption[] => {
    const projectFilterConfig: TextFilterConfig = PaymasterBatchConstants.PaymasterBatch.BatchTextFilterConfig['project'];
    const projectFilters = _.filter(filters, {filterType: projectFilterConfig.type, operator: '[eq]'});
    return _.map(_.head(projectFilters)?.value, (name) => ({
      id: _.find(projects, ['projectName', name])?.projectId,
      value: name,
      type: projectFilterConfig.type
    }));
  }
);

export const selectSelectedBatchInvoiceFilters = createSelector(
  selectFilters,
  (filters: FilterParams[]): FilterOption[] => {
    const invoiceFilterConfig: TextFilterConfig = PaymasterBatchConstants.PaymasterBatch.BatchTextFilterConfig['invoice'];
    const invoiceFilters = _.filter(filters, {filterType: invoiceFilterConfig.type});
    return _.map(_.head(invoiceFilters)?.value, (name) => ({
      id: name,
      value: name,
      type: invoiceFilterConfig.type
    }));
  }
);

export const selectBatchBrowseFilters = createSelector(
  selectSelectedBatchStatusFilters,
  selectSelectedBatchPayrollExceptionsFilters,
  selectSelectedBatchErrorFilters,
  selectProjectLookup,
  selectSelectedProjectFilters,
  selectInvoiceLookup,
  selectSelectedBatchInvoiceFilters,
  (
    statusFilter: FilterOption[], payrollFilter: FilterOption[], errorFilter: FilterOption[], projects: ProjectSummary[], projectFilter: FilterOption[],
    invoices: string[], invoiceFilter: FilterOption[]
  ): TextFilter[] => {
    const batchTextFilterConfigs = PaymasterBatchConstants.PaymasterBatch.BatchTextFilterConfig;
    return [
      {
        title: batchTextFilterConfigs['project'].title,
        options:  _.map(projects, ({projectId: id, projectName: value}) => ({
          id,
          value,
          type: batchTextFilterConfigs['project'].type
        })),
        selectedItems: projectFilter,
        minSearchTextLength: batchTextFilterConfigs['project'].minSearchTextLength
      },
      {
        title: batchTextFilterConfigs['status'].title,
        options: PaymasterBatchConstants.PaymasterBatch.BatchStatusFilterOption,
        selectedItems: statusFilter,
        minSearchTextLength: batchTextFilterConfigs['status'].minSearchTextLength
      },
      {
        title: batchTextFilterConfigs['payroll'].title,
        options: PaymasterBatchConstants.PaymasterBatch.BatchPayrollExceptionsFilterOption,
        selectedItems: payrollFilter,
        minSearchTextLength: batchTextFilterConfigs['payroll'].minSearchTextLength
      },
      {
        title: batchTextFilterConfigs['error'].title,
        options: PaymasterBatchConstants.PaymasterBatch.BatchErrorFilterOption,
        selectedItems: errorFilter,
        minSearchTextLength: batchTextFilterConfigs['error'].minSearchTextLength
      },
      {
        title: batchTextFilterConfigs['invoice'].title,
        options:  _.map(invoices, (invoice: string) => ({
          id: invoice,
          value: invoice,
          type: batchTextFilterConfigs['invoice'].type
        })),
        selectedItems: invoiceFilter,
        minSearchTextLength: batchTextFilterConfigs['invoice'].minSearchTextLength
      },
    ];
  }
);

export const selectAppliedBatchFilterCount = createSelector(
  selectBatchBrowseFilters,
  selectSelectedPaymasters,
  selectSelectedPaymasterManager,
  (batchFilters: TextFilter[], selectedPaymasters: string[], selectedPaymanager: FilterOption) => {
    const selectedBatchFilterCount = _.chain(batchFilters).map(({selectedItems}: TextFilter) => selectedItems).flatten().value().length;
    const selectedPaymasterCount = _.isEmpty(selectedPaymanager) ? _.size(selectedPaymasters) : 1;
    return selectedBatchFilterCount + selectedPaymasterCount;
  }
);

