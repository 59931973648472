import * as _ from 'lodash';
import {createAction} from '@ngrx/store';

import * as fromTimecardEntryView from '../models/timecard-entry.view.model';
import {PunchTab} from '../models/timecard-entry.view.model';
import {CrewDetail, ErrorType, LiteTimecard, Punch, ShiftRate, Shifts, SummaryItems} from '../../../../common/timecard.model';
import {AccountCodeConfig, AccountCodeSegmentConfig} from '../../../../common/project-setting.model';
import {FirstSummaryLineId} from '../../../../common/constants';
import {JobDetails} from '../../../../common/contract-rate.model';
import {WorkLocationPickerArea} from '../../timecard-component/models/work-location-picker.view.model';
import {ColumnConfigStore} from '../../shared/components/manage-column/manage-column.model';
import {StartcardAccountCodeSegment} from '../../../../common/start-card.model';
import {NoteType} from '../../../../common/timecard-note.model';
import {TimecardDetailConstants} from '../../../../constants/timecard-details.constants';
import {getLastOrdinal} from '../../shared/utils';

const _summaryItemsToDefaultLineMapper = (summaryItems: SummaryItems): fromTimecardEntryView.SummaryLine => (summaryItems && {
  summaryId: FirstSummaryLineId,
  date: _.get(summaryItems, `dates.${FirstSummaryLineId}`, null),
  basics: _.get(summaryItems, `basics.${FirstSummaryLineId}`, null),
  jobDetail: _.get(summaryItems, `jobDetails.${FirstSummaryLineId}`, null),
  accountCode: _.get(summaryItems, `accountCodes.${FirstSummaryLineId}`, null),
  payDetail: _.get(summaryItems, `payDetails.${FirstSummaryLineId}`, null)
});

const _getFlatConfig = (accountCodeConfig: AccountCodeConfig, lastOrdinal: number) => _
  .chain(accountCodeConfig)
  .get('segments', {})
  .map((segment: AccountCodeSegmentConfig, index) => {
    const header = segment.header;
    return {
      header,
      obj: {
        header,
        field: header,
        isVisible: true,
        isMandatory: false,
        ordinal: (++index + lastOrdinal)
      }
    };
  })
  .keyBy('header')
  .mapValues('obj')
  .value();
const _hasDeepProperty = (gridSection, prop) => JSON.stringify(gridSection)?.search(`"${prop}":`) > -1;
const _hasDeepValue = (gridSection, prop) => JSON.stringify(gridSection)?.search(`:"${prop}"`) > -1;

const _getDefaultTimeGridColumnConfig = (flatConfig) => {
  const accountCoding = _.merge(TimecardDetailConstants.TimecardEntry.AccountCodingColumnConfigs, {columns: flatConfig});
  return ({
    commonPunch: {
      ...TimecardDetailConstants.TimecardEntry.CommonPunchColumnConfigs,
    },
    reRate: {
      ...TimecardDetailConstants.TimecardEntry.ReRateColumnConfigs,
    },
    details: {
      ...TimecardDetailConstants.TimecardEntry.ShiftDetailsColumnConfigs,
    },
    accountCoding: {
      ...accountCoding,
    },
    breakages: TimecardDetailConstants.TimecardEntry.BreakageColumnConfig
  });
};
const _getDefaultSummaryGridColumnConfig = (flatConfig) => {
  const summaryAccountCoding = _.merge(TimecardDetailConstants.TimecardEntry.SummaryAccountCodingColumnConfigs, {columns: flatConfig});
  const summaryPayDetail = TimecardDetailConstants.TimecardEntry.SummaryPayDetailColumnConfigs;

  return({
    summaryBasics: {
      ...TimecardDetailConstants.TimecardEntry.SummaryBasicsColumnConfigs,
    },
    summaryAccountCode: {
      ...summaryAccountCoding,
    },
    summaryJobDetail: {
      ...TimecardDetailConstants.TimecardEntry.SummaryJobDetailColumnConfigs,
    },
    summaryPayDetail
  });
};

const _applyErrorTreatmentOnTimeGrid = (shifts: Shifts, columnConfig: any) => {
  return ({
    commonPunch: {
      ...columnConfig.commonPunch,
      isShowAllColumns: _hasDeepValue(shifts && shifts.payPunches, ErrorType.NOT_SUBMITTABLE)
    },
    reRate: {
      ...columnConfig.reRate,
      isShowAllColumns: _hasDeepProperty(shifts && shifts.shiftRates, 'errorType')
    },
    details: {
      ...columnConfig.details,
      isShowAllColumns: _hasDeepProperty(shifts && shifts.workDetails, 'errorType')
    },
    accountCoding: {
      ...columnConfig.accountCoding,
      isShowAllColumns: _hasDeepProperty(shifts && shifts.accountCodes, 'errorType')
    },
    breakages: {
      ...columnConfig.breakages,
      isShowAllColumns: _.get(columnConfig, 'breakages.isShowAllColumns', false)
    }
  });
};
const _applyErrorTreatmentOnSummaryGrid = (summaryItems: SummaryItems, columnConfig: any) => {
  return({
    summaryBasics: {
      ...columnConfig.summaryBasics,
      isShowAllColumns: _hasDeepProperty(summaryItems && summaryItems.basics, 'errorType')
    },
    summaryAccountCode: {
      ...columnConfig.summaryAccountCode,
      isShowAllColumns: _hasDeepProperty(summaryItems && summaryItems.accountCodes, 'errorType')
    },
    summaryJobDetail: {
      ...columnConfig.summaryJobDetail,
      isShowAllColumns: _hasDeepProperty(summaryItems && summaryItems.jobDetails, 'errorType')
    },
    summaryPayDetail: {
      ...columnConfig.summaryPayDetail,
      isShowAllColumns: _hasDeepProperty(summaryItems && summaryItems.payDetails, 'errorType')
    }
  });
};

export const SelectField = createAction(
  '[Timecard Entry UI] Select Field',
  (payload: string = '') => ({payload})
);
export const ClearShift = createAction(
  '[Timecard Entry UI] Clear Shift',
  (shiftId: string) => ({shiftId})
);
export const ClipboardShift = createAction(
  '[Timecard Entry UI] Update Clipboard Shift',
  (shift: fromTimecardEntryView.Shift) => ({shift})
);
export const CopyShift = createAction(
  '[Timecard Entry UI] Copy Shift',
  (shiftId: string) => ({shiftId})
);
export const CopyShiftUptoFriday = createAction(
  '[Timecard Entry UI] Copy shift to all days upto friday',
  (shiftId: string) => ({shiftId})
);
export const DefaultShift = createAction(
  '[Timecard Entry UI] update Default Shift',
  (shift: fromTimecardEntryView.Shift) => ({shift})
);
export const DefaultSummaryLine = createAction(
  '[Timecard Entry UI] Update default summaryLine',
  (summaryItems: SummaryItems) => ({
    summaryItems,
    summaryLine: <fromTimecardEntryView.SummaryLine>_summaryItemsToDefaultLineMapper(summaryItems)
  })
);
export const TimecardAccountCodeConfig = createAction(
  '[Timecard Entry UI] Update account code config',
  (projectId: string, accountCodeConfig: StartcardAccountCodeSegment[]) => ({projectId, accountCodeConfig})
);
export const TimecardIsDirty = createAction(
  '[Timecard Entry UI] Set Timecard Dirty Status'
);
export const TimecardIsPristine = createAction(
  '[Timecard Entry UI] Set Timecard Pristine Status'
);
export const TimecardSaving = createAction(
  '[Timecard Entry UI] Set Timecard Saving Status'
);
export const RefreshingMobile = createAction(
  '[Timecard Entry UI] Set Mobile Update Saving Status'
);
export const RefreshMobileCompleted = createAction(
  '[Timecard Entry UI] Set Timecard Refresh Mobile Saved Status'
);
export const TimecardCalculating = createAction(
  '[Timecard Entry UI] Set Timecard Calculating'
);
export const TimecardSaveCompleted = createAction(
  '[Timecard Entry UI] Set Timecard Saved Status'
);
export const TimecardCalculateCompleted = createAction(
  '[Timecard Entry UI] Set Timecard Calculate Completed'
);
export const SaveRevisedTimecardWithErrorSuccessful = createAction(
  '[Timecard Entry UI] Set Revised Timecard With Error Completed'
);
export const CopySummaryLine = createAction(
  '[Timecard Entry UI] Copy SummaryLine',
  (summaryId: string) => ({summaryId})
);
export const ClipboardSummaryLine = createAction(
  '[Timecard Entry UI] Update Clipboard SummaryLine',
  (summaryLine: fromTimecardEntryView.SummaryLine) => ({summaryLine})
);
export const ClearClipboard = createAction(
  '[Timecard Entry UI] Clear Clipboard'
);
export const CopyPunchDownSection = createAction(
  '[Timecard Entry UI] Copy Punch Down Section',
  (fromShiftId: string, toShiftId: string) => ({fromShiftId, toShiftId})
);
export const CopyAccountDownSection = createAction(
  '[Timecard Entry UI] Copy Account Down Section',
  (fromShiftId: string, toShiftId: string) => ({fromShiftId, toShiftId})
);
export const CopyRateDownSection = createAction(
  '[Timecard Entry UI] Copy Rate Down Section',
  (fromShiftId: string, toShiftId: string) => ({fromShiftId, toShiftId})
);
export const CopyDetailDownSection = createAction(
  '[Timecard Entry UI] Copy Detail Down Section',
  (fromShiftId: string, toShiftId: string) => ({fromShiftId, toShiftId})
);
export const CopySummaryWorkDateDownSection = createAction(
  '[Timecard Entry UI] Copy Summary Work Date Down Section',
  (fromSummaryId: string, toSummaryId: string) => ({fromSummaryId, toSummaryId})
);
export const CopySummaryBasicsDownSection = createAction(
  '[Timecard Entry UI] Copy Summary Basics Down Section',
  (fromSummaryId: string, toSummaryId: string) => ({fromSummaryId, toSummaryId})
);
export const CopySummaryAccountsDownSection = createAction(
  '[Timecard Entry UI] Copy Summary Accounts Down Section',
  (fromSummaryId: string, toSummaryId: string) => ({fromSummaryId, toSummaryId})
);
export const CopySummaryJobDetailsDownSection = createAction(
  '[Timecard Entry UI] Copy Summary Job Details Down Section',
  (fromSummaryId: string, toSummaryId: string) => ({fromSummaryId, toSummaryId})
);
export const CopySummaryPayDetailsDownSection = createAction(
  '[Timecard Entry UI] Copy Summary Pay Details Down Section',
  (fromSummaryId: string, toSummaryId: string) => ({fromSummaryId, toSummaryId})
);
export const RetrieveAccountCodeConfig = createAction(
  '[Timecard Entry UI] Retrieve Account Code Config',
  (projectId: string) => ({projectId})
);
export const RetrieveAccountCodeConfigSuccessful = createAction(
  '[Timecard Entry UI] Retrieve Account Code Config Successful',
  (payload: AccountCodeConfig) => ({payload})
);
export const RetrieveAccountCodeConfigFailed = createAction(
  '[Timecard Entry UI] Retrieve Account Code Config Failed',
  (payload: string) => ({payload})
);
export const OpenAssignBatchModal = createAction(
  '[Timecard Entry UI] Open Assign Timecard To Batch overlay'
);
export const CreateBatchFailed = createAction(
  '[Timecard Entry UI] Assign Batch is failed',
  (error: Error) => ({error})
);

export const OpenSummaryLineChangesModal = createAction(
  '[Timecard Entry UI] Open Change History overlay',
  (summaryId: string) => ({summaryId})
);
export const CloseSummaryLineChangesModal = createAction(
  '[Timecard Entry UI] Close Change History overlay'
);
export const CloseAssignBatchModal = createAction(
  '[Timecard Entry UI] Close Assign Timecard To Batch overlay'
);
export const RetrieveJobDetails = createAction(
  '[Timecard Entry UI] Retrieve Job Details',
  (startcardId: string, projectId: string) => ({startcardId, projectId})
);
export const RetrieveJobDetailsSuccessful = createAction(
  '[Timecard Entry UI] Retrieve Job Details Successful',
  (jobs: JobDetails[]) => ({jobs})
);
export const RetrieveJobDetailsFail = createAction(
  '[Timecard Entry UI] Retrieve Job Details Failed'
);
export const DisplayWorkLocationPickerForShift = createAction(
  '[Timecard Entry UI] Display Work Location Picker For Shift Area',
  (id: string, location: WorkLocationPickerArea) => ({id, location})
);
export const CloseWorkLocationPicker = createAction(
  '[Timecard Entry UI] Close Work Location Picker For Shift Area'
);
export const DisplayWorkLocationPickerForSummary = createAction(
  '[Timecard Entry UI] Display Work Location Picker For Summary Area',
  (id: string, location: WorkLocationPickerArea) => ({id, location})
);
export const DisplayWorkLocationPickerForDefaultValue = createAction(
  '[Timecard Entry UI] Display Work Location Picker For Default Value',
  (location: WorkLocationPickerArea) => ({location})
);
export const DisplayLoadingScreen = createAction(
  '[Timecard Entry UI] Display Loading Screen',
  (loadingText: string) => ({loadingText})
);
export const DismissLoadingScreen = createAction(
  '[Timecard Entry UI] Dismiss Loading Screen'
);
export const RetrievePrevTimecardSuccessful = createAction(
  '[Timecard Entry UI] Update previous timecard',
  (payload: LiteTimecard) => ({payload})
);
export const RetrieveNextTimecardSuccessful = createAction(
  '[Timecard Entry UI] Update next timecard',
  (payload: LiteTimecard) => ({payload})
);
export const RetrieveNextTimecardFailed = createAction(
  '[Timecard Entry UI] Retrieve Next Timecard Failed',
  (error: any) => ({error})
);
export const RetrievePrevTimecardFailed = createAction(
  '[Timecard Entry UI] Retrieve Prev Timecard Failed',
  (error: any) => ({error})
);
export const ResetPrevTimecard = createAction(
  '[Timecard Entry UI] Reset previous timecard'
);
export const ResetNextTimecard = createAction(
  '[Timecard Entry UI] Reset next timecard'
);
export const SetTimecardReviseStatus = createAction(
  '[Timecard Entry UI] Update Timecard Revise Status',
  (isRevised: boolean) => ({isRevised})
);

// TODO: Hack - STF-2893 - Making Column Config Sticky for Paymaster Release in Nov 2019
// TODO: Refactor the Manage Column to decouple how to rehydrate the state and apply special treatment like when errors need to be displayed.
export const InitializeManageColumnConfigs = createAction(
  '[Timecard Entry UI] Initialize Column Configs',
  (shiftAndSummary: any, accountCodeConfig: AccountCodeConfig, uiColumnConfig: ColumnConfigStore) => {
    let timeGridColumnConfig;
    let summaryGridColumnConfig;
    // getLastOrdinal - Last ordinal value of AccountCodingColumnConfigs constant to act as offset for codingColConfigs ordinals
    const flatConfig = _getFlatConfig(accountCodeConfig, getLastOrdinal(TimecardDetailConstants.TimecardEntry.AccountCodingColumnConfigs.columns));
    const isUiColumnConfigEmpty = _.isEmpty(uiColumnConfig?.CREW);
    const defaultTimeGridColumnConfig = _getDefaultTimeGridColumnConfig(flatConfig);
    if (isUiColumnConfigEmpty) {
      timeGridColumnConfig = _applyErrorTreatmentOnTimeGrid(shiftAndSummary.shifts, defaultTimeGridColumnConfig);
      summaryGridColumnConfig = _applyErrorTreatmentOnSummaryGrid(shiftAndSummary.summaryItems,
        _getDefaultSummaryGridColumnConfig(flatConfig));
    } else {
      // is account code config the same?
      const storedAccountCodeColumnConfig = _.get(uiColumnConfig, 'CREW.newTCTimeGrid.accountCoding.columns', null);
      if (_.isEqual(_.keys(flatConfig), _.keys(_.omit(storedAccountCodeColumnConfig, ['glShortcut', 'split'])))) { // use stored value
        timeGridColumnConfig = _applyErrorTreatmentOnTimeGrid(shiftAndSummary.shifts, uiColumnConfig?.CREW?.newTCTimeGrid);
        summaryGridColumnConfig = _applyErrorTreatmentOnSummaryGrid(shiftAndSummary.summaryItems, uiColumnConfig?.CREW?.newTCSummaryGrid);
      } else {
        // Use default configs while keeping user preference for Breakage section
        const isBreakageExpanded = _.get(uiColumnConfig, 'CREW.newTCTimeGrid.breakages.isShowAllColumns', false);
        timeGridColumnConfig = _applyErrorTreatmentOnTimeGrid(shiftAndSummary.shifts, {
          ...defaultTimeGridColumnConfig,
          breakages: {
            ...defaultTimeGridColumnConfig.breakages,
            isShowAllColumns: isBreakageExpanded
          }
        });
        summaryGridColumnConfig =
          _applyErrorTreatmentOnSummaryGrid(shiftAndSummary.summaryItems, _getDefaultSummaryGridColumnConfig(flatConfig));
      }
    }
    return {
      shiftAndSummary,
      accountCodeConfig,
      uiColumnConfig,
      timeGridColumnConfig: timeGridColumnConfig,
      summaryGridColumnConfig: summaryGridColumnConfig
    };
  }
);

export const ApplyTemplate = createAction(
  '[Timecard Entry UI] Applying Template'
);
export const ApplyTemplateSuccessful = createAction(
  '[Timecard Entry UI] Applying Template Successful'
);
export const ApplyTemplateFailed = createAction(
  '[Timecard Entry UI] Applying Template Failed',
  (errorMsg: string) => ({errorMsg})
);
export const OpenTimecardNotesPanel = createAction(
  '[Timecard Entry UI] Open Timecard Notes Panel',
  (noteType: NoteType) => ({noteType})
);
export const CloseTimecardNotesPanel = createAction(
  '[Timecard Entry UI] Close Timecard Notes Panel',
  () => ({payload: false})
);
export const ShouldResetTimecardNotesPanel = createAction(
  '[Timecard Entry UI] Should Reset Timecard Notes Panel',
  (status: boolean) => ({status})
);
export const SelectedTimesTab = createAction(
  '[Timecard Entry UI] Selected Times Tab',
  (selectedPunchTab: PunchTab) => ({selectedPunchTab})
);
export const ResetSelectedTimesTab = createAction(
  '[Timecard Entry UI] Reset Selected Times Tab'
);
export const SetIsPayCodeFocused = createAction(
  '[Timecard Entry UI] Should set isPayCodeFocused',
  (isPayCodeFocused: boolean) => ({isPayCodeFocused})
);
export const OpenMobileNotesPanel = createAction(
  '[Timecard Entry UI] Open Timecard Notes Panel',
  (noteType: NoteType) => ({noteType})
);
export const TimecardDuplicating = createAction(
  '[Timecard Entry UI] TC is duplicating'
);
export const TimecardDuplicateCompleted = createAction(
  '[Timecard Entry UI] TC duplicate is completed'
);
export const Reset = createAction(
  '[Timecard Entry UI] Reset Timecard'
);

export const SetContinueTcNavigationWithoutSaving = createAction(
  '[Timecard Entry UI] Should set isContinueTcNavigationWithoutSaving',
  (isContinueTcNavigationWithoutSaving: boolean) =>
    ({isContinueTcNavigationWithoutSaving})
);

export const UpdateShiftConsequentialChange = createAction(
  '[Timecard Entry UI] Update Timecard Shift Consequential Change',
  (shiftId: string, punch: Punch, shiftRate: ShiftRate, detail: CrewDetail) => ({shiftId, punch, shiftRate, detail})
);

export const AddDayType = createAction(
  '[Timecard Entry UI] Add Day Type',
  (shiftId: string, shiftDate: string) => ({shiftId, shiftDate})
);

export const RemoveDayType = createAction(
  '[Timecard Entry UI] Remove Day Type',
  (shiftId: string, shiftDate: string) => ({shiftId, shiftDate})
);
export const OpenWtcReportPanel = createAction(
  '[Timecard Entry UI] Open report panel',
  () => ({isWtcReportPanelOpenedInTcDetails: true})
);
export const CloseWtcReportPanel = createAction(
  '[Timecard Entry UI] Close report panel',
  () => ({isWtcReportPanelOpenedInTcDetails: false})
);
export const OpenAuditHistoryReportPanel = createAction(
  '[Timecard Entry UI] Open Audit History Report panel',
  () => ({isAuditHistoryReportPanelOpened: true})
);
export const CloseAuditHistoryReportPanel = createAction(
  '[Timecard Entry UI] Close Audit History Report panel',
  () => ({isAuditHistoryReportPanelOpened: false})
);
export const CloseAllPanelExpectNotePanel = createAction(
  '[Timecard Entry UI] Close all panel expect Note panel',
);
export const RetrieveVaPayrollExceptionTimecardSuccessful = createAction(
  '[Timecard Entry UI] Retrieve Va Payroll Exception Successful',
  (isVaPayrollExceptionTimecard: boolean) => ({isVaPayrollExceptionTimecard})
);
export const RetrieveVaPayrollExceptionTimecardFailed = createAction(
  '[Timecard Entry UI] Retrieve Va Payroll Exception Failed',
  (error: any) => ({error})
);
